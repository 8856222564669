<!--
 * @Author: yuwenwen
 * @Date: 2022-11-25 15:08:50
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-06-25 17:14:31
 * @FilePath: \nc-funeral-mobile\src\views\products\orderDetails.vue
-->
<template>
  <div class="order-details">
    <!-- 殡仪用品 -->
    <div class="product-info">
      <div class="title-header">
         <div class="titles">殡仪用品</div>
         <div>共{{orderDetails.totalNum}}件</div>
      </div>
      <div class="product-item-details" v-for="(item,index) in productList" :key="index">
         <img :src="item.productImage" />
         <div class="product-name">
            <div class="name">{{item.productName}}</div>
            <div class="count">数量:{{item.salesNum}}</div>
         </div>
         <div class="product-price">¥{{item.salesPrice}}</div>
      </div>
    </div>
    <!-- 订单编号、订单时间、商品数量、合计 -->
    <div class="order-details-info">
        <div class="item">
           <div class="color-999">订单编号</div>
           <div class="color-333">{{orderDetails.orderNo}}</div>
        </div>
        <div class="item mt-16">
           <div class="color-999">订单时间</div>
           <div class="color-333">{{orderDetails.createTime}}</div>
        </div>
        <div class="item mt-16">
           <div class="color-999">商品数量</div>
           <div class="color-333">x{{orderDetails.allNum}}</div>
        </div>
        <div class="item mt-16">
           <div class="color-333">合计</div>
           <div class="color-orange">¥{{orderDetails.totalPrice}}</div>
        </div>
    </div>

    <!-- 购买人信息 -->
    <div class="product-info mt-16">
      <div class="title-header">
         <div class="titles">购买人信息</div>
      </div>
      <div class="item mt-16">
           <div class="color-999">姓名</div>
           <div class="color-333">{{orderDetails.buyName}}</div>
        </div>
        <div class="item mt-16">
           <div class="color-999">联系电话</div>
           <div class="color-333">{{orderDetails.buyMobile}}</div>
        </div>
        <div class="item mt-16">
           <div class="color-999">与逝者关系</div>
           <div class="color-333">{{relationship[orderDetails.relation]}}</div>
        </div>
    </div>
    <!-- 逝者信息 -->
    <div class="product-info mt-16">
      <div class="title-header">
         <div class="titles">逝者信息</div>
      </div>
      <div class="item mt-16">
           <div class="color-999">姓名</div>
           <div class="color-333">{{orderDetails.deadName}}</div>
        </div>
        <div class="item mt-16">
           <div class="color-999">身份证号</div>
           <div class="color-333">{{orderDetails.deadCardCode}}</div>
        </div>
    </div>

    <!-- 操作按钮 -->
    <!-- <div class="ope-btn">立即支付</div> -->
    
  </div>
</template>
<script>
import { getOrderDetails } from "@/api/product.js";
import {getImagesUrl} from '@/utils/funeral.js'
import {getDicts} from '@/api/common.js';
export default {
  name: "OrderDetails",
  data() {
    return {
      orderNo: "",
      orderDetails:{},
      productList:[],
      relationship:{},
      relation:''
    };
  },
  created() {
    let { orderNo } = this.$route.query;
    this.orderNo = orderNo;
    this.getRelationDict();
    this.getOrderDetails();
  },
  methods: {
     // 获取与逝者关系数据字典
     getRelationDict(){
      getDicts('relation').then(res=>{
        res.map((item)=>{
          this.relationship[item.value] = item.text;
        })
      })
    },
    // 
    async getOrderDetails() {
      let [err, res] = await this.$awaitWrap(getOrderDetails(this.orderNo));
      if (err) {
        return;
      }
      if(res.code == 200){
        this.orderDetails = res.data;
        this.orderDetails.allNum = 0;
        this.productList = res.data.orderDetails.map(item=>{
            this.orderDetails.allNum = this.orderDetails.allNum + item.salesNum;
            if(item.productImage){
                item.productImage = getImagesUrl(item.productImage.split(',')[0])
            }
            
            return item;
        });

      }
      console.log(this.orderDetails);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/product.less";
</style>